<template>
  <v-data-table
    :loading="!transferRegists"
    :items="transferRegists || []"
    :headers="headers"
    :items-per-page="5"
  >
    <template v-slot:[`item.bankCd`]="{ item: { bankCd } }">
      {{ bankCd | bankCodeName }}
    </template>
    <template v-slot:[`item.crDate`]="{ item: { crDate } }">
      {{ crDate | moment('yyyy-MM-DD HH:mm:ss') }}
    </template>
    <template v-slot:[`item.upDate`]="{ item: { upDate } }">
      {{ upDate | moment('yyyy-MM-DD HH:mm:ss') }}
    </template>
    <template v-slot:[`item.isLock`]="{ item: { isLock } }">
      <span :class="{ 'error--text': isLock }">
        {{ isLock ? '락계좌' : '일반계좌' }}
      </span>
    </template>
    <template v-slot:[`item.applicationType`]="{ item: { applicationType } }">
      <span :class="applicationType === '1' ? 'primary--text' : 'error--text'">
        {{
          applicationType === '1'
            ? '등록'
            : applicationType === '2'
            ? '해지'
            : applicationType
        }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import collectionAccountMixin from './collectionAccountMixin.js'

export default {
  mixins: [collectionAccountMixin],
  data() {
    return {
      headers: [
        { text: 'idx', value: 'idx', sortable: false },
        {
          text: '락계좌여부',
          value: 'isLock',
          sortable: false,
          width: 100,
        },
        {
          text: '등록/해지 여부 (applicationType)',
          value: 'applicationType',
          sortable: false,
          width: 100,
        },
        { text: '결과코드', value: 'retCode', sortable: false },
        { text: '결과메세지', value: 'retMsg', sortable: false, width: 200 },
        { text: '요청시간', value: 'crDate', sortable: false, width: 180 },
        { text: '수정시간', value: 'upDate', sortable: false, width: 180 },
        { text: 'payrNo', value: 'payrNo', sortable: false },
        { text: '은행', value: 'bankCd', sortable: false },
        { text: '계좌번호', value: 'bankAccount', sortable: false },
      ],
    }
  },
  async created() {
    await this.getTransferRegists()
  },
}
</script>

<style></style>
