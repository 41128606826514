var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading":!_vm.transferRegists,"items":_vm.transferRegists || [],"headers":_vm.headers,"items-per-page":5},scopedSlots:_vm._u([{key:"item.bankCd",fn:function(ref){
var bankCd = ref.item.bankCd;
return [_vm._v(" "+_vm._s(_vm._f("bankCodeName")(bankCd))+" ")]}},{key:"item.crDate",fn:function(ref){
var crDate = ref.item.crDate;
return [_vm._v(" "+_vm._s(_vm._f("moment")(crDate,'yyyy-MM-DD HH:mm:ss'))+" ")]}},{key:"item.upDate",fn:function(ref){
var upDate = ref.item.upDate;
return [_vm._v(" "+_vm._s(_vm._f("moment")(upDate,'yyyy-MM-DD HH:mm:ss'))+" ")]}},{key:"item.isLock",fn:function(ref){
var isLock = ref.item.isLock;
return [_c('span',{class:{ 'error--text': isLock }},[_vm._v(" "+_vm._s(isLock ? '락계좌' : '일반계좌')+" ")])]}},{key:"item.applicationType",fn:function(ref){
var applicationType = ref.item.applicationType;
return [_c('span',{class:applicationType === '1' ? 'primary--text' : 'error--text'},[_vm._v(" "+_vm._s(applicationType === '1' ? '등록' : applicationType === '2' ? '해지' : applicationType)+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }